var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-area slider-direction-layout1 slider-layout1 overlay-slider"},[_c('div',{staticClass:"bend niceties preview-1"},[_c('div',{staticClass:"slides",attrs:{"id":"ensign-nivoslider-1"}},[(_vm.showData)?_c('carousel3',{attrs:{"autoplay":false,"nav":false,"dots":false,"items":1,"responsive":{
         0: { items: 1 },
         576: { items: 1 },
         600: { items: 1 },
         768: { items: 1 },
         1200: { items: 1 },
         1500: { items: 1 },
       }}},_vm._l((_vm.allSlider),function(slider){return _c('div',{key:slider.id,staticClass:"item slide-1",on:{"click":function($event){return _vm.redirectUrl(slider.link)}}},[_c('img',{attrs:{"src":_vm.$root.URL_ROOT+'uploads/Slider/'+slider.image,"alt":slider.image}}),_c('div',{staticClass:"carousel-caption"},[_c('h1',{staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(slider.heading)+" ")]),_c('h3',[_vm._v(_vm._s(slider.heading2))]),_c('br')])])}),0):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }